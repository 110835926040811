import React from 'react';
import { BsYoutube, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { images } from '../Constansts';
const SocialMediaPhone = () => (
  <>
  <div className="app__social_Phone">
    <div>
    <a href={`https://www.youtube.com/@yaryackvideoproduction1626`} target="blank">
      {/* <BsYoutube /> */}
      <img src={images.youtube}/>
     
      </a>
    </div>
    <div>
    <a href={`https://www.facebook.com/profile.php?id=100064125130777`} target="blank">
    <img src={images.facebook}/>
      
      {/* <FaFacebookF /> */}
      </a>
    </div>
    <div>
    <a href={`https://www.instagram.com/yaryack_photos`} target="blank">
      {/* <BsInstagram /> */}
      <img src={images.instagram}/>
      
      </a>
    </div>
  </div>
   
  
   </>
);

export default SocialMediaPhone;