import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import logo from "../assets/logo.png";
import facebook from "../assets/facebook.png";
import youtube from "../assets/youtube.png";
import instagram from "../assets/instagram.png";
// import video3 from "../assets/video3.mp4";
import profile0 from "../assets/profile0.png";
import footer from "../assets/footer.png";
export default {
  email,
  mobile,
  logo,
  profile0,
  footer,
  youtube,
  instagram,
  facebook,
//   video2,
//   video3,
};
